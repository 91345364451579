var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"platform-color-palette-list"},[_c('Action-panel',{attrs:{"isReady":_vm.isPageReady}},[_c('Button-base',{attrs:{"type":2,"text":_vm.translations.labels.button_add_event,"hoverInfo":_vm.translations.labels.button_add_event_hover,"onClick":_vm.newItem}})],1),_c('TableList',{attrs:{"is-ready":_vm.isPageReady,"headers":_vm.headers,"items":_vm.model},scopedSlots:_vm._u([{key:"item.first",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('input',{staticClass:"color",attrs:{"type":"color","disabled":""},domProps:{"value":item.first}})])])]}},{key:"item.second",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('input',{staticClass:"color",attrs:{"type":"color","disabled":""},domProps:{"value":item.second}})])])]}},{key:"item.third",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('input',{staticClass:"color",attrs:{"type":"color","disabled":""},domProps:{"value":item.third}})])])]}},{key:"item.fourth",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('input',{staticClass:"color",attrs:{"type":"color","disabled":""},domProps:{"value":item.fourth}})])])]}},{key:"item.fifth",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('input',{staticClass:"color",attrs:{"type":"color","disabled":""},domProps:{"value":item.fifth}})])])]}},{key:"item.sixth",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('input',{staticClass:"color",attrs:{"type":"color","disabled":""},domProps:{"value":item.sixth}})])])]}},{key:"item.isSelected",fn:function(ref){
var item = ref.item;
return [_c('tr',[(item.isSelected)?_c('td',[_c('div',{staticClass:"btn selected"})]):_vm._e(),(!item.isSelected)?_c('td',[_c('div',{staticClass:"btn not-selected",on:{"click":function($event){return _vm.select(item.id)}}})]):_vm._e()])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('router-link',{attrs:{"to":("/configurations/color-palette/edit/" + (item.id))}},[_c('Round',{attrs:{"type":2}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }