<template>
  <div class="platform-color-palette-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_event"
        :hoverInfo="translations.labels.button_add_event_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList :is-ready="isPageReady" :headers="headers" :items="model">
      <template v-slot:item.first="{ item }">
        <tr>
          <td>
            <input class="color" type="color" disabled :value="item.first" />
          </td>
        </tr>
      </template>
      <template v-slot:item.second="{ item }">
        <tr>
          <td>
            <input class="color" type="color" disabled :value="item.second" />
          </td>
        </tr>
      </template>
      <template v-slot:item.third="{ item }">
        <tr>
          <td>
            <input class="color" type="color" disabled :value="item.third" />
          </td>
        </tr>
      </template>
      <template v-slot:item.fourth="{ item }">
        <tr>
          <td>
            <input class="color" type="color" disabled :value="item.fourth" />
          </td>
        </tr>
      </template>
      <template v-slot:item.fifth="{ item }">
        <tr>
          <td>
            <input class="color" type="color" disabled :value="item.fifth" />
          </td>
        </tr>
      </template>
      <template v-slot:item.sixth="{ item }">
        <tr>
          <td>
            <input class="color" type="color" disabled :value="item.sixth" />
          </td>
        </tr>
      </template>
      <template v-slot:item.isSelected="{ item }">
        <tr>
          <td v-if="item.isSelected">
            <div class="btn selected"></div>
          </td>
          <td v-if="!item.isSelected">
            <div class="btn not-selected" @click="select(item.id)"></div>
          </td>
        </tr>
      </template>
      <template v-slot:item.edit="{ item }">
        <tr>
          <td>
            <router-link :to="`/configurations/color-palette/edit/${item.id}`">
              <Round :type="2">
                <v-icon dark>mdi-pencil</v-icon>
              </Round>
            </router-link>
          </td>
        </tr>
      </template>
    </TableList>
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import { themeService } from "@/services/themeService";
import { toastService } from "@/services/toastService.js";
import TableList from "@/components/table/TableList";

@Component({
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    TableList,
  },
})
export default class PlatformColorPalette_list extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.color_palette_title,
        value: "title",
      },
      {
        text: this.translations.labels.color_palette_first,
        value: "first",
      },
      {
        text: this.translations.labels.color_palette_second,
        value: "second",
      },
      {
        text: this.translations.labels.color_palette_third,
        value: "third",
      },
      {
        text: this.translations.labels.color_palette_fourth,
        value: "fourth",
      },
      {
        text: this.translations.labels.color_palette_fifth,
        value: "fifth",
      },
      {
        text: this.translations.labels.color_palette_sixth,
        value: "sixth",
      },
      {
        text: this.translations.labels.color_palette_current,
        value: "isSelected",
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/configurations/color-palette/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
    ];
  }

  newItem() {
    this.$router.push("/configurations/color-palette/new");
  }

  toastSuccessLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.event_disable)
      : toastService.success(this.translations.success.event_enable);
  }

  async select(id) {
    this.isPageReady = false;
    try {
      await themeService.select(id);
      toastService.success(this.translations.success.color_palette_selected);
      this.model = await themeService.list();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
      this.isPageReady = true;
    }
  }

  async init() {
    try {
      this.model = await themeService.list();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;

  &.selected {
    background: radial-gradient(darkgray 50%, white 60%);
    border: 4px solid darkgray;
    cursor: pointer;
  }

  &.not-selected {
    border: 4px solid darkgray;
    cursor: pointer;
  }
}

.color {
  height: 40px;
}
</style>
